import notif from "./modules/notif";

$(document).ready(() => {
    const BtnAddToCart = $('[data-add-to-cart]')
    const Modal = $('[data-modal-calculator]')
    const ModalTotal = Modal.find('[data-modal-total]')
    const ModalProductTitle = Modal.find('[data-modal-product-title]')
    const InputItemCount = $('input[name="product_count"]')
    const InputCountPackages = $('input[name="count_packages"]')
    const HeaderCountItems = $('[data-header-count-items]')
    const CartTotal = $('[data-cart-total]')

    const updateModalTotal = () => {
        let price = parseInt(ModalTotal.attr('data-product-price'))
        let count = parseInt(InputItemCount.val())

        ModalTotal.html(`${count*price} &#8381;`)
    }

    const mathFloor = val => {
        return Math.floor(val * 100) / 100
    }

    const disableSubmitCart = data => {
        if(!data) {
            if(!$('[data-product-li]').length) {
                $('[data-cart-product-list]').html('<div>Список товаров в корзине пуст</div>')
            }
            $("[data-checkout-button]").addClass('disabled')
        } else {
            $("[data-checkout-button]").removeClass('disabled')
        }
    }

    // Open Calculator
    BtnAddToCart.click(function (e) {
        const btn = $(this)
        let product_id = btn.data('product-id')
        let price = parseInt(btn.data('product-price'))
        let packageSize = parseInt(btn.data('package-size'))
        let countInCart = parseInt(btn.attr('data-count-in-cart'))
        let ProductTitle = $(`[data-product-title][data-product-id="${product_id}"]`).text()

        InputItemCount.val(countInCart)
        InputCountPackages.val(mathFloor(countInCart/packageSize))
        Modal.find('[data-plus-package]').attr('data-count', packageSize)
        Modal.find('[data-minus-package]').attr('data-count', -packageSize)
        Modal.attr('data-package-size', packageSize)
        Modal.attr('data-product-id', product_id)
        ModalProductTitle.text(ProductTitle)
        ModalTotal.attr('data-product-price', price)

        updateModalTotal()
    })

    // Click FastChangeCount
    $('[data-fast-change-count]').click(function (e) {
        const el = $(this)
        let count = parseInt(InputItemCount.val())

        count += parseInt(el.attr('data-count'))
        if (count < 0) {
            InputItemCount.val(0).trigger('change')
            InputCountPackages.val(0)
            return
        }
        InputItemCount.val(count).trigger('change')
    })

    // Click Clear Calculator Form
    $('[data-clear-calculator-form]').click(e => {
        InputItemCount.val(0).trigger('change')
        InputCountPackages.val(0)
        updateModalTotal()
    })

    // Change Input ItemCount
    InputItemCount.change(function (e) {
        const el = $(this)
        let packageSize = Modal.attr('data-package-size')

        if (el.val() <= 0) {
            el.val(0)
            InputCountPackages.val(0)
        }

        let num = el.val()/packageSize

        InputCountPackages.val(mathFloor(num))

        updateModalTotal()
    })

    // Change Input Count Packages
    InputCountPackages.change(function (e) {
        const el = $(this)
        let packageSize = Modal.attr('data-package-size')

        if (el.val() <= 0) {
            InputItemCount.val(0)
            el.val(0)
        }

        let num = el.val()*packageSize

        InputItemCount.val(mathFloor(num))

        updateModalTotal()
    })

    // Submit Calculator Form
    $('[data-submit-calculator-form]').click(function () {
        const route = $(this).data('route')
        const product_id = Modal.attr('data-product-id')
        const product_count = InputItemCount.val()
        const product_count_packages = InputCountPackages.val()

        axios.put(route, {
            product_id,
            product_count
        }).then(res => {
            let num = mathFloor(product_count_packages)

            HeaderCountItems.text(res.data.products.length)
            CartTotal.text(res.data.total)
            $(`[data-add-to-cart][data-product-id="${product_id}"]`).attr('data-count-in-cart', product_count)
            $(`[data-count-in-cart-box][data-product-id="${product_id}"]`).text(product_count)
            $(`[data-count-packages-in-cart-box][data-product-id="${product_id}"]`).text(num)
            $(`[data-product-subtotal-box][data-product-id="${product_id}"]`).text(ModalTotal.text())
            if (product_count > 0) {
                $(`[data-toggle-color][data-product-id="${product_id}"]`).addClass('btn-success').removeClass('btn-primary')
            } else {
                $(`[data-toggle-color][data-product-id="${product_id}"]`).addClass('btn-primary').removeClass('btn-success')
            }
            Modal.modal('hide')

            disableSubmitCart(res.data.products.length)
        }).catch(err => {
            notif.error('Что-то пошло не так. Повторите попытку позже');
            console.log(err)
        })
    })

    // Remove Item from Cart
    $('[data-remove-from-cart]').click(function () {
        const el = $(this)
        const route = el.data('route')
        const product_id = el.data('product-id')
        const li = $(`[data-product-li][data-product-id=${product_id}]`)

        axios.delete(route, {
            data: {
                product_id
            }
        }).then(res => {
            HeaderCountItems.text(res.data.products.length)
            CartTotal.text(res.data.total)
            li.slideUp("fast", () => {
                li.remove()
                disableSubmitCart(res.data.products.length)
            })
        }).catch(err => {
            li.slideDown("fast")
            notif.error('Что-то пошло не так. Повторите попытку позже')
            console.log(err)
        })
    })
})
